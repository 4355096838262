export default {
  "nav": {
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
    "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About"])},
    "skills": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skills"])},
    "experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Experience"])},
    "portfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portfolio"])},
    "contactme": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contactme"])}
  },
  "home": {
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hi, I'am Leo!!"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I am a developer with experience in software analysis, design and development producing quality work."])},
    "role_fullstack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fullstack developer"])},
    "role_front": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frontend developer"])},
    "role_back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Backend developer"])},
    "role_analyst": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Systems analyst"])},
    "contactme": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Me"])},
    "scroll_daown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scroll down"])}
  },
  "about": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About Me"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My introduction"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I am a full stack developer with knowledge and experience working on web technologies. I also like photography, discovering new places, playing video games, practicing judo and learning about new technologies."])},
    "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download CV"])}
  },
  "skills": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skills"])},
  "experience": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Experience"])},
    "Job1": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SYSTEMS ANALYST"])},
      "place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AVP Support & Solutions"])},
      "period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From July 1, 2021"])},
      "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assigned customer - Telcel - Billing Area"])},
      "tasks": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Implementation of developments and software changes in the system SISAP ( Sistema de Ajustes y Pagos )"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analysis and Design for a new Telcel biller"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reports generation"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Backend programming and automated processes"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taking requirements and documentation"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Execution of test cases"])}
      }
    },
    "Job2": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SYSTEMS ANALYST"])},
      "place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AsTeci S.A de C.V"])},
      "period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From February 7, 2019 to June 31, 2021"])},
      "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assigned customer - Telcel - Billing Area"])},
      "tasks": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Implementation of developments and software changes in the system SISAP ( Sistema de Ajustes y Pagos )"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analysis and Design for a new Telcel biller"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reports generation"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Backend programming and automated processes"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taking requirements and documentation"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Execution of test cases"])}
      }
    },
    "Job3": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SOFTWARE DEVELOPER"])},
      "place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instituto Nacional Electoral (INE)"])},
      "period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From January 16, 2017 to December 31, 2021"])},
      "tasks": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taking requirements and documentation"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Implementation and development in the SNN (Sistema nacional de notificaciones)"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maintenance and attention to defects"])}
      }
    },
    "Job4": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SOFTWARE DEVELOPER"])},
      "place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wellcom S.A de C.V"])},
      "period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From October 13, 2015 to December 31, 2016"])},
      "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assigned customer - PROSA"])},
      "tasks": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maintenance of catalogs"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analysis, Design and Development of the SGM (Sistema de Gestión de Mensajes)"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["System Support SAG (Sistema de Auditoría y Gestión)"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maintenance and attention to defects"])}
      }
    }
  },
  "portfolio": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portfolio"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Most recent work"])},
    "projects": {
      "judotecnia": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Judotecnia Site"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Web about the Judotecnia team and information about the next tournaments creating the matches between users registered."])}
      },
      "judotecniaScore": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Judotecnia Score"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This site is used while a tournament to identificate the winner. This site can do a countdown and take the score for any competitor."])}
      },
      "pokemon": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokedex"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This site is a pokedex where we can see information about pokemon's. This site consume the API from pokeAPI."])}
      },
      "marvel": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marvel Super Heroes"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This site is a library of Marvel characters where you can read information about them."])}
      },
      "cinemaDB": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CinemaDB"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This site is a library of movies where you can read information about them and get information about trending movies."])}
      }
    }
  },
  "contact": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Me"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get in touch"])},
    "call": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Call me"])},
    "location": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location"])},
      "place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["México - México City"])}
    },
    "form": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your name"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your email"])},
      "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subject"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your message"])},
      "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send message"])}
    }
  },
  "footer": {
    "follow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FOLLOW ME"])}
  }
}