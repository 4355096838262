<template>
  <section id="home" class="flex justify-center items-center min-h-screen px-1 pt-20">
      <div class="">
          <div class="grid grid-cols-12">
              <div class="col-span-2 md:col-span-1 flex flex-col justify-center items-center gap-4">
                  <a href="https://www.linkedin.com/in/leonardo-de-jesus-rosas-santillan-48a974122" target="_blank">
                    <unicon name="linkedin-alt" fill="#2564eb" class="w-6 h-6"></unicon>
                  </a>
                  <a href="https://github.com/LeonardoRosas92" target="_blank">
                    <unicon name="github-alt" fill="#2564eb" class="w-6 h-6"></unicon>
                  </a>
                  <a href="https://www.instagram.com/leonardo.rosas.357" target="_blank">
                    <unicon name="instagram" fill="#2564eb" class="w-6 h-6"></unicon>
                  </a>
              </div>
              <div class="col-span-10 md:col-span-6 md:order-1 flex justify-center items-center">
                  <svg class="w-64 md:w-80 fill-blue-600" viewBox="0 0 200 187" xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink">
                      <mask id="mask0" mask-type="alpha">
                          <path d="M190.312 36.4879C206.582 62.1187 201.309 102.826 182.328 134.186C163.346 165.547 
                          130.807 187.559 100.226 186.353C69.6454 185.297 41.0228 161.023 21.7403 129.362C2.45775 
                          97.8511 -7.48481 59.1033 6.67581 34.5279C20.9871 10.1032 59.7028 -0.149132 97.9666 
                          0.00163737C136.23 0.303176 174.193 10.857 190.312 36.4879Z" />
                      </mask>
                      <g mask="url(#mask0)">
                          <path d="M190.312 36.4879C206.582 62.1187 201.309 102.826 182.328 134.186C163.346 
                          165.547 130.807 187.559 100.226 186.353C69.6454 185.297 41.0228 161.023 21.7403 
                          129.362C2.45775 97.8511 -7.48481 59.1033 6.67581 34.5279C20.9871 10.1032 59.7028 
                          -0.149132 97.9666 0.00163737C136.23 0.303176 174.193 10.857 190.312 36.4879Z" />
                          <image class="w-full" x="0" y="-50" href="@/assets/Perfil.png" />
                      </g>
                  </svg>
              </div>
              <div class="col-span-12 md:col-span-5 px-4">
                  <h1 class="w-0 max-w-min inline-flex text-3xl md:text-5xl font-bold mt-5 p-1 border-r-2 border-solid border-black overflow-hidden whitespace-nowrap font-mono">
                    {{ $t('home.name') }}
                  </h1>
                  <div class="h-7 overflow-hidden mt-3">
                    <div class="roller transition-all">
                      <h3 class="p-0 m-0 text-lg md:text-2xl text-gray-600">
                        {{ $t('home.role_fullstack') }}</h3>
                      <h3 class="p-0 m-0 text-lg md:text-2xl text-gray-600">
                        {{ $t('home.role_back') }}</h3>
                      <h3 class="p-0 m-0 text-lg md:text-2xl text-gray-600">
                        {{ $t('home.role_analyst') }}</h3>
                      <h3 class="p-0 m-0 text-lg md:text-2xl text-gray-600">
                        {{ $t('home.role_analyst') }}</h3>
                    </div>
                  </div>
                  <p class="md:text-lg text-gray-500 mt-3">
                    {{ $t('home.description') }}
                  </p>
                  <a href="#contact" class="md:text-xl bg-blue-500 hover:bg-blue-600 text-white inline-flex justify-center items-center px-5 py-3 rounded-lg gap-1 md:gap-2 mt-8">
                    {{ $t('home.contactme') }}
                    <unicon name="navigator" fill="#FFFFFF" class="w-6 h-6"></unicon>
                  </a>
              </div>
          </div>
          <div class="px-4 flex justify-center mt-6 md:mt-12">
              <a href="#about" class=" text-white md:text-xl inline-flex justify-center items-center px-4 py-3 rounded-lg gap-1 transition-all">
                  <unicon name="mouse-alt" fill="#2564eb" class="w-6 h-6"></unicon>
                  <span class="text-black">{{ $t('home.scroll_daown') }}</span>
                  <unicon name="arrow-down" fill="#2564eb" class="w-6 h-6 animate-bounce"></unicon>
              </a>
          </div>
      </div>
  </section>
</template>
<style scoped >
h1 {
  animation: typing 4s steps(15) 1s infinite, blink 1s steps(1) infinite;
}
.roller {
  animation: roller 30s 1s infinite;
}
@keyframes typing {
  0% {
    width: 0;
  }
  50% {
    width: 100%;
  }
  0% {
    width: 0;
  }
}
@keyframes blink {
  50% {
    border-color: transparent;
  }
}
@keyframes roller {
  16.6% {
    transform: translateY(-25%);
  }
  33.3% {
    transform: translateY(-50%);
  }
  50% {
    transform: translateY(-75%);
  }
  66.3% {
    transform: translateY(-50%);
  }
  83.3% {
    transform: translateY(-25%);
  }
  100% {
    transform: translateY(0%);
  }
}
</style>