<template>
  <section id="skills" class="flex flex-col gap-2 items-center min-h-screen px-1 pt-20">
    <h2 class="text-2xl md:text-3xl">{{ $t('skills') }}</h2>
    <div class="flex flex-col items-center max-w-4xl">
      <div class="w-full flex justify-center">
        <img src="@/assets/Skills.png" alt="Logo HTML 5" class="max-w" >
      </div>
      <div class="flex flex-col md:flex-row gap-5">
        <div class="w-full md:w-1/2 space-y-5">
          <h3 class="text-center text-xl font-mono font-semibold">FRONTEND</h3>
          <div class="flex flex-wrap justify-center gap-5">
            <figure>
              <img src="@/assets/Logos/HTML5.svg" alt="Logo Html" class="w-20">
              <figcaption class="text-center text-gray-500 dark:text-gray-400">HTML</figcaption>
            </figure>
            <figure>
              <img src="@/assets/Logos/CSS3.svg" alt="Logo Css" class="w-20">
              <figcaption class="text-center text-gray-500 dark:text-gray-400">CSS</figcaption>
            </figure>
            <figure>
              <img src="@/assets/Logos/JavaScript.svg" alt="Logo Javascript" class="w-20">
              <figcaption class="text-center text-gray-500 dark:text-gray-400">Javascript</figcaption>
            </figure>
            <figure>
              <img src="@/assets/Logos/Sass.svg" alt="Logo Sass" class="w-20">
              <figcaption class="text-center text-gray-500 dark:text-gray-400">SASS</figcaption>
            </figure>
            <figure>
              <img src="@/assets/Logos/Tailwind_CSS.svg" alt="Logo Tailwind" class="w-20">
              <figcaption class="text-center text-gray-500 dark:text-gray-400">Tailwind</figcaption>
            </figure>
            <figure>
              <img src="@/assets/Logos/Bootstrap.svg" alt="Logo Bootstrap" class="w-20">
              <figcaption class="text-center text-gray-500 dark:text-gray-400">Bootstrap</figcaption>
            </figure>
            <figure>
              <img src="@/assets/Logos/Vue.js.svg" alt="Logo Vue" class="w-20">
              <figcaption class="text-center text-gray-500 dark:text-gray-400">Vue</figcaption>
            </figure>
            <figure>
              <img src="@/assets/Logos/React.svg" alt="Logo React" class="w-20">
              <figcaption class="text-center text-gray-500 dark:text-gray-400">React</figcaption>
            </figure>
          </div>
        </div>
        <div class="w-full md:w-1/2 space-y-5">
          <h3 class="text-center text-xl font-mono font-semibold">BACKEND</h3>
          <div class="flex flex-wrap justify-center gap-5">
            <figure>
              <img src="@/assets/Logos/Java.svg" alt="Logo Java" class="w-20">
              <figcaption class="text-center text-gray-500 dark:text-gray-400">Java</figcaption>
            </figure>
            <figure>
              <img src="@/assets/Logos/Spring.svg" alt="Logo Spring" class="w-20">
              <figcaption class="text-center text-gray-500 dark:text-gray-400">Spring</figcaption>
            </figure>
            <figure>
              <img src="@/assets/Logos/Node.js.svg" alt="Logo Node" class="w-20">
              <figcaption class="text-center text-gray-500 dark:text-gray-400">Node JS</figcaption>
            </figure>
            <figure>
              <img src="@/assets/Logos/Express.svg" alt="Logo Express" class="w-20">
              <figcaption class="text-center text-gray-500 dark:text-gray-400">Express JS</figcaption>
            </figure>
            <figure>
              <img src="@/assets/Logos/MySQL.svg" alt="Logo MySql" class="w-20">
              <figcaption class="text-center text-gray-500 dark:text-gray-400">MySql</figcaption>
            </figure>
            <figure>
              <img src="@/assets/Logos/SQL_Developer.svg" alt="Logo Sql Developer" class="w-20">
              <figcaption class="text-center text-gray-500 dark:text-gray-400">Sql Dev</figcaption>
            </figure>
            <figure>
              <img src="@/assets/Logos/Hibernate.svg" alt="Logo Hibernate" class="w-20">
              <figcaption class="text-center text-gray-500 dark:text-gray-400">Hibernate</figcaption>
            </figure>
            <figure>
              <img src="@/assets/Logos/MongoDB.svg" alt="Logo Mongo DB" class="w-20">
              <figcaption class="text-center text-gray-500 dark:text-gray-400">Mongo DB</figcaption>
            </figure>
            <figure>
              <img src="@/assets/Logos/Mongoose.js.svg" alt="Logo Mongoose" class="w-20">
              <figcaption class="text-center text-gray-500 dark:text-gray-400">Mongoose</figcaption>
            </figure>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {

}
</script>