<template>
  <section id="experience" class="flex flex-col gap-5 items-center min-h-screen px-6 pt-20">
    <h2 class="text-2xl md:text-3xl mb-3">{{ $t('experience.title') }}</h2>
    <ol class="w-full md:grid md:grid-cols-2 md:gap-5">                  
        <li class="border-l border-gray-200 md:relative pl-6 pb-6">            
            <span class="absolute flex items-center justify-center w-6 h-6 bg-blue-100 rounded-full left-3 md:-left-3 ring-8 ring-white ">
                <svg class="w-2.5 h-2.5 text-blue-800" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                    <path d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z"/>
                </svg>
            </span>
            <h3 class="flex items-center mb-1 text-lg font-semibold text-gray-900">
              {{ $t('experience.Job1.title') }} 
              <span class="bg-blue-100 text-blue-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded  ml-3">
                Latest
              </span></h3>
            <time class="block mb-2 text-sm font-normal leading-none text-gray-400">
              {{ $t('experience.Job1.place') }}
            </time>
            <time class="block mb-2 text-sm font-normal leading-none text-gray-400">
              {{ $t('experience.Job1.period') }}
            </time>
            <time class="block mb-2 text-sm font-normal leading-none text-gray-400">
              {{ $t('experience.Job1.customer') }}
            </time>
            <p class="mb-0.5 text-base font-normal text-gray-500">
              •  {{ $t('experience.Job1.tasks.1') }}
            </p>
            <p class="mb-0.5 text-base font-normal text-gray-500">
              •  {{ $t('experience.Job1.tasks.2') }}
            </p>
            <p class="mb-0.5 text-base font-normal text-gray-500">
              •  {{ $t('experience.Job1.tasks.3') }}
            </p>
            <p class="mb-0.5 text-base font-normal text-gray-500">
              •  {{ $t('experience.Job1.tasks.4') }}
            </p>
            <p class="mb-0.5 text-base font-normal text-gray-500">
              •  {{ $t('experience.Job1.tasks.5') }}
            </p>
            <p class="mb-0.5 text-base font-normal text-gray-500">
              •  {{ $t('experience.Job1.tasks.6') }}
            </p>
        </li>
        <li class="border-l border-gray-200 md:relative pl-6 pb-6">            
            <span class="absolute flex items-center justify-center w-6 h-6 bg-blue-100 rounded-full left-3 md:-left-3 ring-8 ring-white ">
                <svg class="w-2.5 h-2.5 text-blue-800" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                    <path d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z"/>
                </svg>
            </span>
            <h3 class="flex items-center mb-1 text-lg font-semibold text-gray-900">
              {{ $t('experience.Job2.title') }} 
            </h3>
            <time class="block mb-2 text-sm font-normal leading-none text-gray-400">
              {{ $t('experience.Job2.place') }} 
            </time>
            <time class="block mb-2 text-sm font-normal leading-none text-gray-400">
              {{ $t('experience.Job2.period') }} 
            </time>
            <time class="block mb-2 text-sm font-normal leading-none text-gray-400">
              {{ $t('experience.Job2.customer') }} 
            </time>
            <p class="mb-0.5 text-base font-normal text-gray-500">
              •  {{ $t('experience.Job2.tasks.1') }} 
            </p>
            <p class="mb-0.5 text-base font-normal text-gray-500">
              •  {{ $t('experience.Job2.tasks.2') }} 
            </p>
            <p class="mb-0.5 text-base font-normal text-gray-500">
              •  {{ $t('experience.Job2.tasks.3') }} 
            </p>
            <p class="mb-0.5 text-base font-normal text-gray-500">
              •  {{ $t('experience.Job2.tasks.4') }} 
            </p>
            <p class="mb-0.5 text-base font-normal text-gray-500">
              •  {{ $t('experience.Job2.tasks.5') }} 
            </p>
            <p class="mb-0.5 text-base font-normal text-gray-500">
              •  {{ $t('experience.Job2.tasks.6') }} 
            </p>
        </li>
        <li class="border-l border-gray-200 md:relative pl-6 pb-6">            
            <span class="absolute flex items-center justify-center w-6 h-6 bg-blue-100 rounded-full left-3 md:-left-3 ring-8 ring-white ">
                <svg class="w-2.5 h-2.5 text-blue-800" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                    <path d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z"/>
                </svg>
            </span>
            <h3 class="flex items-center mb-1 text-lg font-semibold text-gray-900">
              {{ $t('experience.Job3.title') }} 
            </h3>
            <time class="block mb-2 text-sm font-normal leading-none text-gray-400">
              {{ $t('experience.Job3.place') }} 
            </time>
            <time class="block mb-2 text-sm font-normal leading-none text-gray-400">
              {{ $t('experience.Job3.period') }} 
            </time>
            <p class="mb-0.5 text-base font-normal text-gray-500">
              •  {{ $t('experience.Job3.tasks.1') }} 
            </p>
            <p class="mb-0.5 text-base font-normal text-gray-500">
              •  {{ $t('experience.Job3.tasks.2') }} 
            </p>
            <p class="mb-0.5 text-base font-normal text-gray-500">
              •  {{ $t('experience.Job3.tasks.3') }} 
            </p>
        </li>
        <li class="border-l border-gray-200 md:relative pl-6 pb-6">            
            <span class="absolute flex items-center justify-center w-6 h-6 bg-blue-100 rounded-full left-3 md:-left-3 ring-8 ring-white ">
                <svg class="w-2.5 h-2.5 text-blue-800" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                    <path d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z"/>
                </svg>
            </span>
            <h3 class="flex items-center mb-1 text-lg font-semibold text-gray-900">
              {{ $t('experience.Job4.title') }} 
            </h3>
            <time class="block mb-2 text-sm font-normal leading-none text-gray-400">
              {{ $t('experience.Job4.place') }} 
            </time>
            <time class="block mb-2 text-sm font-normal leading-none text-gray-400">
              {{ $t('experience.Job4.period') }} 
            </time>
            <time class="block mb-2 text-sm font-normal leading-none text-gray-400">
              {{ $t('experience.Job4.customer') }} 
            </time>
            <p class="mb-0.5 text-base font-normal text-gray-500">
              •  {{ $t('experience.Job4.tasks.1') }} 
            </p>
            <p class="mb-0.5 text-base font-normal text-gray-500">
              •  {{ $t('experience.Job4.tasks.2') }} 
            </p>
            <p class="mb-0.5 text-base font-normal text-gray-500">
              •  {{ $t('experience.Job4.tasks.3') }} 
            </p>
            <p class="mb-0.5 text-base font-normal text-gray-500">
              •  {{ $t('experience.Job4.tasks.4') }} 
            </p>
        </li>
    </ol>
  </section>
</template>

<script>
export default {};
</script>
