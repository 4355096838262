<template>
  <nav class=" px-2 py-1 fixed w-full z-20 top-0 left-0 border-b border-gray-200 bg-white">
    <div class="flex flex-wrap items-center mx-auto justify-between">
      <a href="https://www.leonardorosas.com" class="flex">
        <span class="polygon-l self-end text-xl tracking-widest font-shadows font-bold bg-blue-600 pl-1 pr-2 mt-1 text-white">
          Leonardo
        </span>
        <span class="polygon-r self-start text-xl tracking-widest font-shadows bg-blue-600 pl-2 pr-1 mb-1 text-white font-bold">
          Rosas
        </span>
      </a>
      <div class="flex flex-wrap items-center justify-end gap-1">
        <div class="flex md:order-2 py-2">
          <button
            data-collapse-toggle="navbar-sticky"
            type="button"
            class="inline-flex items-center p-2 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200"
            aria-controls="navbar-sticky"
            aria-expanded="false"
            @click="toogleMenu()"
          >
            <span class="sr-only">Open main menu</span>
            <svg
              class="w-6 h-6"
              aria-hidden="true"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </button>
        </div>
        <div
          class="absolute top-16 items-center justify-between w-full md:static md:flex md:w-auto md:order-0"
          id="navbar-sticky"
          :class="{ hidden: showMenu }"
        >
          <ul
            class="flex flex-col p-3 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-4 md:mt-0 md:text-sm md:font-medium md:border-0 md:bg-white"
          >
            <li>
              <!-- text-white bg-blue-700 -->
              <a
                href="#home"
                class="nav_link block py-2 pl-3 pr-4 text-white bg-blue-700 rounded md:bg-transparent md:text-blue-700 md:p-0"
                @click="selectItem"
                >{{ $t('nav.home') }}</a
              >
            </li>
            <li>
              <a
                href="#about"
                class="nav_link block py-2 pl-3 pr-4 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 "
                @click="selectItem"
                >{{ $t('nav.about') }}</a
              >
            </li>
            <li>
              <a
                href="#skills"
                class="nav_link block py-2 pl-3 pr-4 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0"
                @click="selectItem"
                >{{ $t('nav.skills') }}</a
              >
            </li>
            <li>
              <a
                href="#experience"
                class="nav_link block py-2 pl-3 pr-4 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0"
                @click="selectItem"
                >{{ $t('nav.experience') }}</a
              >
            </li>
            <li>
              <a
                href="#portfolio"
                class="nav_link block py-2 pl-3 pr-4 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0"
                @click="selectItem"
                >{{ $t('nav.portfolio') }}</a
              >
            </li>
            <li>
              <a
                href="#contact"
                class="nav_link block py-2 pl-3 pr-4 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0"
                @click="selectItem"
                >{{ $t('nav.contactme') }}</a
              >
            </li>
          </ul>
        </div>
        <select v-model="$i18n.locale" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5">
          <option v-for="(locale, i) in locales" :key="`locale-${i}`" :value="locale">
            {{ locale.toUpperCase() }}
          </option>
        </select>
      </div>
    </div>
  </nav>
</template>

<script>
import { ref } from "vue";
export default {
  name: "HeaderComponent",
  setup() {
    const showMenu = ref(true);
    function toogleMenu() {
      showMenu.value = !showMenu.value;
    }
    function selectItem(event){
      if(!showMenu.value){
        toogleMenu();
      }
      console.log(event.target.hash);
      const navLink = document.querySelectorAll('.nav_link');
      //console.log(navLink);
      navLink.forEach(a => {
        if(event.target.hash === a.hash){
          a.classList.add('text-white', 'bg-blue-700', 'rounded', 'md:bg-transparent', 'md:text-blue-700', 'md:p-0');
          a.classList.remove('text-gray-700', 'rounded', 'hover:bg-gray-100', 'md:hover:bg-transparent', 'md:hover:text-blue-700');
        }else{
          a.classList.remove('text-white', 'bg-blue-700', 'rounded', 'md:bg-transparent', 'md:text-blue-700', 'md:p-0');
          a.classList.add('text-gray-700', 'rounded', 'hover:bg-gray-100', 'md:hover:bg-transparent', 'md:hover:text-blue-700', 'md:p-0');
        }
      })
    }
    return { toogleMenu, showMenu , selectItem, locales: ["en", "es"]};
  },
};
</script>

<style></style>
