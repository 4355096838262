<template>
  <section id="contact" class="flex flex-col items-center min-h-screen pt-20 pb-2 gap-3">
    <Toaster position="top-right" richColors />
    <h2 class="text-2xl md:text-3xl">{{ $t('contact.title') }} </h2>
    <span class="text-sm md:text-lg md:mb-10 md:mt-5">{{ $t('contact.subtitle') }}</span>
    <div class="w-full grid grid-cols-12 gap-4">
        <div class="col-span-12 md:col-span-6 flex flex-col items-center gap-2">
            <div class="grid grid-cols-12 items-center w-full px-2">
                <div class="col-span-2 flex flex-col items-center justify-center">
                  <unicon name="phone-alt" fill="#2564eb" class="w-6 h-6"></unicon>
                </div>
                <div class="col-span-10">
                    <h3 class="contact__title ">{{ $t('contact.call') }}</h3>
                    <span class="text-gray-600 text-xs md:text-sm">(+52) 55-68-18-53-40</span>
                </div>
            </div>
            <div class="grid grid-cols-12 items-center w-full px-2">
              <div class="col-span-2 flex flex-col items-center justify-center">
                <unicon name="envelope-alt" fill="#2564eb" class="w-6 h-6"></unicon>
              </div>
              <div class="col-span-10">
                  <h3 class="contact__title">Email</h3>
                  <span class="text-gray-600 text-xs md:text-sm">leonardo.rosas.isc@gmail.com</span>
              </div>
            </div>
            <div class="grid grid-cols-12 items-center w-full px-2">
              <div class="col-span-2 flex flex-col items-center justify-center">
                <unicon name="map-marker" fill="#2564eb" class="w-6 h-6"></unicon>
              </div>
              <div class="col-span-10">
                  <h3 class="contact__title">{{ $t('contact.location.title') }}</h3>
                  <span class="text-gray-600 text-xs md:text-sm">{{ $t('contact.location.place') }}</span>
              </div>
            </div>
            <div class="w-full hidden md:flex justify-center items-center">
              <img src="@/assets/Contact.png" alt="Contact image" class="max-h-96">
            </div>
        </div>
        <div class="col-span-12 md:col-span-6 px-4">
          <form @submit.prevent="sendEmail" class="space-y-4">
              <div>
                  <label for="name" class="block mb-2 text-sm font-medium text-gray-900">
                    {{ $t('contact.form.name') }}
                  </label>
                  <input type="text" id="name" v-model="name" class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5" placeholder="Nancy Morales" required>
              </div>
              <div>
                  <label for="email" class="block mb-2 text-sm font-medium text-gray-900">
                    {{ $t('contact.form.email') }}
                  </label>
                  <input type="email" id="email" v-model="email" class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5" placeholder="name@email.com" required>
              </div>
              <div>
                  <label for="subject" class="block mb-2 text-sm font-medium text-gray-900">
                    {{ $t('contact.form.subject') }}
                  </label>
                  <input type="text" id="subject" v-model="subject" class="block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500" placeholder="Let me know how we I help you" required>
              </div>
              <div class="sm:col-span-2">
                  <label for="message" class="block mb-2 text-sm font-medium text-gray-900">
                    {{ $t('contact.form.message') }}
                  </label>
                  <textarea id="message" rows="6" v-model="message" class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg shadow-sm border border-gray-300 focus:ring-primary-500 focus:border-primary-500" placeholder="Leave a comment..." required></textarea>
              </div>
              <button type="submit" class="py-3 px-5 text-sm font-medium text-center text-white rounded-lg bg-blue-600 sm:w-fit hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300">
                {{ $t('contact.form.send') }}
              </button>
          </form>
        </div>
    </div>
  </section>
</template>

<script>
import { ref } from 'vue';
import { Toaster, toast } from 'vue-sonner'
import emailjs from 'emailjs-com';
export default{
    name: 'ContactComponent',
    components: { Toaster },
    setup(){
        const name = ref('');
        const email = ref('');
        const subject = ref('');
        const message = ref('');
        const sendEmail = () => {
            try {
                emailjs.send(process.env.VUE_APP_SERVICE_ID_MAIL,process.env.VUE_APP_TEMPLATE_ID_MAIL,{
                  name: name.value,
                  email: email.value,
                  subject: subject.value,
                  message: message.value,
                },process.env.VUE_APP_USER_ID_KEY);
                toast.success( 'Message sended!!!' );
            } catch (error) {
                console.log({error})
                toast.success( 'Something went wrong!!!' );
            }
            name.value = '';
            email.value = '';
            subject.value = '';
            message.value = '';
        }
        return {
            sendEmail,name,email,subject,message
        }
    }
}
</script>