import { createApp } from 'vue'
import App from './App.vue'
import './styles/styles.css'
import Unicon from 'vue-unicons'
import { uniLinkedinAlt, uniGithubAlt, uniNavigator, uniMouseAlt, uniArrowDown, uniDownloadAlt, uniInstagram, uniPhoneAlt, uniEnvelopeAlt, uniMapMarker } from 'vue-unicons/dist/icons'
import i18n from './i18n'

Unicon.add([uniLinkedinAlt, uniGithubAlt, uniNavigator, uniMouseAlt, uniArrowDown, uniDownloadAlt, uniInstagram, uniPhoneAlt, uniEnvelopeAlt, uniMapMarker])

createApp(App).use(i18n).use(Unicon).mount('#app')
