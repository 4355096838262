export default {
  "nav": {
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inicio"])},
    "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acerca de mi"])},
    "skills": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Habilidades"])},
    "experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Experiencia"])},
    "portfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portafolio"])},
    "contactme": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contactame"])}
  },
  "home": {
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hola, soy Leo!!"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soy un desarrollador con experiencia en análisis, diseño y desarrollo de software produciendo un trabajo de calidad."])},
    "role_fullstack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fullstack developer"])},
    "role_front": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desarrollador frontend"])},
    "role_back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desarrollador backend"])},
    "role_analyst": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analista de sistemas"])},
    "contactme": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contactame"])},
    "scroll_daown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desliza"])}
  },
  "about": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acerca de mí"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mi introducción"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soy un desarrollador full stack con conocimiento y experiencia trabajando en tecnologías web. También me gusta la fotografía, descubrir nuevos lugares, jugar videojuegos, practicar judo y aprender sobre nuevas tecnologías."])},
    "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descargar CV"])}
  },
  "skills": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Habilidades"])},
  "experience": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Experiencia"])},
    "Job1": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ANALISTA DE SISTEMAS"])},
      "place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AVP Support & Solutions"])},
      "period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desde 1 de Julio del 2021"])},
      "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliente asignado - Telcel - Área de facturación"])},
      "tasks": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Implementación de desarrollos y cambios de software en (SISAP) Sistema de Ajustes y Pagos"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Análisis y Diseño para nuevo facturador de Telcel"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generación de reportes"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Programación de backend y procesos automatizados"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toma de requerimientos y documentación"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ejecución de casos de prueba"])}
      }
    },
    "Job2": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ANALISTA DE SISTEMAS"])},
      "place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AsTeci S.A de C.V"])},
      "period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desde 7 de Febrero del 2019 al 31 de Junio del 2021"])},
      "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliente asignado - Telcel - Área de facturación"])},
      "tasks": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Implementación de desarrollos y cambios de software en (SISAP) Sistema de Ajustes y Pagos"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Análisis y Diseño para nuevo facturador de Telcel"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generación de reportes"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Programación de backend y procesos automatizados"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toma de requerimientos y documentación"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ejecución de casos de prueba"])}
      }
    },
    "Job3": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DESARROLLADOR DE SOFTWARE"])},
      "place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instituto Nacional Electoral (INE)"])},
      "period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desde 16 de Enero del 2017 al 31 de Diciembre del 2018"])},
      "tasks": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toma de requerimientos"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Implementación y desarrollo en el (SNN) Sistema Nacional de Notificaciones. Realizando optimización de código"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mantenimiento y atención de defectos"])}
      }
    },
    "Job4": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DESARROLLADOR DE SOFTWARE"])},
      "place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wellcom S.A de C.V"])},
      "period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desde 13 de Octubre del 2015 Al 31 de Diciembre del 2016"])},
      "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliete asignado - PROSA"])},
      "tasks": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mantenimiento de catálogos."])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Análisis, Diseño y Desarrollo del SGM (Sistema de Gestión de Mensajes)"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soporte al sistema (SAG) Sistema de Auditoria y Gestión"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mantenimiento y atención de defectos"])}
      }
    }
  },
  "portfolio": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portafolio"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trabajo más reciente"])},
    "projects": {
      "judotecnia": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sitio Judotecnia"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sitio Web sobre el equipo de Judotecnia e información sobre los próximos torneos creando los partidos entre los usuarios registrados."])}
      },
      "judotecniaScore": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Judotecnia Score"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este sitio se utiliza durante un torneo para identificar al ganador. Este sitio puede hacer una cuenta regresiva y tomar la puntuación de cualquier competidor."])}
      },
      "pokemon": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokedex"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este sitio es un pokedex donde podemos ver información sobre los pokemon. Este sitio consume la API de pokeAPI."])}
      },
      "marvel": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marvel Super Heroes"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este sitio es un pokedex donde podemos ver información sobre los pokemon. Este sitio consume la API de pokeAPI."])}
      },
      "cinemaDB": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CinemaDB"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este sitio es una biblioteca de películas donde puede leer información sobre ellas y obtener información sobre películas de moda."])}
      }
    }
  },
  "contact": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contactame"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ponerse en contacto"])},
    "call": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Llamame"])},
    "location": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ubicación"])},
      "place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["México - Ciudad de México"])}
    },
    "form": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tu nombre"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tu email"])},
      "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asunto"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mensaje"])},
      "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar mensaje"])}
    }
  },
  "footer": {
    "follow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SIGUEME"])}
  }
}