<template>
  <footer class="bg-white">
      <div class="mx-auto w-full max-w-screen-xl p-4 py-6 lg:py-8">
          <div class="md:flex md:justify-between">
            <div class="mb-6 md:mb-0">
              <a href="https://www.leonardorosas.com" class="flex">
                <span class="polygon-l self-end text-xl tracking-widest font-shadows bg-blue-600 pl-1 pr-2 mt-1 text-white font-bold">
                  Leonardo
                </span>
                <span class="polygon-r self-start text-xl tracking-widest font-shadows bg-blue-600 pl-2 pr-1 mb-1 text-white font-bold">
                  Rosas
                </span>
              </a>
            </div>
            <div class="grid grid-cols-2 gap-8 sm:gap-6 sm:grid-cols-3">
                <div>
                    <h2 class="mb-6 text-sm font-semibold text-gray-900 uppercase">Menu</h2>
                    <ul class="text-gray-500 font-medium">
                        <li class="mb-4">
                            <a href="#home" class="hover:underline">
                                {{ $t('nav.home') }}
                            </a>
                        </li>
                        <li class="mb-4">
                            <a href="#about" class="hover:underline">
                                {{ $t('nav.about') }}
                            </a>
                        </li>
                        <li class="mb-4">
                            <a href="#skills" class="hover:underline">
                                {{ $t('nav.skills') }}
                            </a>
                        </li>
                        <li class="mb-4">
                            <a href="#experience" class="hover:underline">
                                {{ $t('nav.experience') }}
                            </a>
                        </li>
                        <li class="mb-4">
                            <a href="#portfolio" class="hover:underline">
                                {{ $t('nav.portfolio') }}
                            </a>
                        </li>
                        <li class="mb-4">
                            <a href="#contact" class="hover:underline">
                                {{ $t('nav.contactme') }}
                            </a>
                        </li>
                    </ul>
                </div>
                <div>
                    <h2 class="mb-6 text-sm font-semibold text-gray-900 uppercase">
                        {{ $t('footer.follow') }}
                    </h2>
                    <ul class="text-gray-500 font-medium">
                        <li class="mb-4">
                            <a href="https://github.com/LeonardoRosas92" class="hover:underline ">Github</a>
                        </li>
                        <li>
                            <a href="https://www.instagram.com/leonardo.rosas.357" class="hover:underline">Instagram</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <hr class="my-6 border-gray-200 sm:mx-auto lg:my-8" />
        <div class="flex justify-center">
            <span class="text-sm text-gray-500 text-center ">© 2023 <a href="https://www.leonardorosas.com/" class="hover:underline">Leonardo Rosas™</a>. All Rights Reserved.
            </span>
        </div>
      </div>
  </footer>
</template>

<script>
export default {

}
</script>
