<template>
  <section id="portfolio" class="flex flex-col items-center pt-20 mx-2 pb-2 gap-2 md:gap-0">
    <h2 class="text-2xl md:text-3xl">{{ $t('portfolio.title') }}</h2>
    <span class="text-sm md:text-lg md:mb-10 md:mt-5">{{ $t('portfolio.subtitle') }}</span>
    <div class="h-full w-full flex flex-col md:flex-row-reverse md:items-center flex-1">
      <div class="hidden md:w-full md:flex md:justify-center md:items-center">
        <img src="@/assets/Portfolio.png" alt="Contact image" class="w-full object-fill">
      </div>
      <swiper :navigation="true" :loop="true" :modules="modules">
        <swiper-slide class="h-full w-full flex justify-center">
          <div class="w-full flex flex-col justify-center gap-5 lg:gap-14">
            <div class="w-full h-1/2 flex justify-center items-center">
              <img src="@/assets/Carrousell/Judotecnia.png" alt="Judotecnia Web Site" class="w-full max-w-xs md:max-w-md rounded-xl">
            </div>
            <div class="w-full text-center">
                <h3 class="text-center text-2xl text-red-600">{{ $t('portfolio.projects.judotecnia.title') }}</h3>
                <p class="text-justify p-3">{{ $t('portfolio.projects.judotecnia.description') }}</p>
                <div class="p-3 flex flex-wrap gap-3 justify-center">
                  <img src="@/assets/Logos/HTML5.svg" alt="Logo HTML 5" class="w-10">
                  <img src="@/assets/Logos/JavaScript.svg" alt="Logo Javascript" class="w-10">
                  <img src="@/assets/Logos/Tailwind_CSS.svg" alt="Logo Tailwind" class="w-10">
                  <img src="@/assets/Logos/Vue.js.svg" alt="Logo Vue" class="w-10">
                  <img src="@/assets/Logos/Node.js.svg" alt="Logo Node" class="w-10">
                  <img src="@/assets/Logos/Express.svg" alt="Logo Express" class="w-10">
                  <img src="@/assets/Logos/MongoDB.svg" alt="Logo MongoDB" class="w-10">
                  <img src="@/assets/Logos/Mongoose.js.svg" alt="Logo Mongoose" class="w-10">
                </div>
                <a href="https://www.judotecnia.com" target="_blank" class="md:text-xl bg-blue-500 hover:bg-blue-600 text-white inline-flex justify-center items-center px-5 py-3 rounded-lg gap-1 md:gap-2 mt-2">
                    Demo
                    <unicon name="navigator" fill="#FFFFFF" class="w-6 h-6"></unicon>
                </a>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide class="h-full w-full flex justify-center">
          <div class="w-full flex flex-col justify-center gap-5 lg:gap-14">
            <div class="w-full h-1/2 flex justify-center items-center">
              <img src="@/assets/Carrousell/Judotecnia-Score.png" alt="Judotecnia Web Site" class="w-full max-w-xs md:max-w-md rounded-xl">
            </div>
            <div class="w-full text-center">
                <h3 class="text-center text-2xl text-red-600">{{ $t('portfolio.projects.judotecniaScore.title') }}</h3>
                <p class="text-justify p-3">{{ $t('portfolio.projects.judotecniaScore.description') }}</p>
                <div class="p-3 flex flex-wrap gap-3 justify-center">
                  <img src="@/assets/Logos/HTML5.svg" alt="Logo HTML 5" class="w-10">
                  <img src="@/assets/Logos/JavaScript.svg" alt="Logo Javascript" class="w-10">
                  <img src="@/assets/Logos/Tailwind_CSS.svg" alt="Logo Tailwind" class="w-10">
                  <img src="@/assets/Logos/Vue.js.svg" alt="Logo Vue" class="w-10">
                </div>
                <a href="https://judotecnia-score.netlify.app/" target="_blank" class="md:text-xl bg-blue-500 hover:bg-blue-600 text-white inline-flex justify-center items-center px-5 py-3 rounded-lg gap-1 md:gap-2 mt-2">
                    Demo
                    <unicon name="navigator" fill="#FFFFFF" class="w-6 h-6"></unicon>
                </a>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide class="h-full w-full flex justify-center">
          <div class="w-full flex flex-col justify-center gap-5 lg:gap-14">
            <div class="w-full h-1/2 flex justify-center items-center">
              <img src="@/assets/Carrousell/Pokedex.png" alt="Judotecnia Web Site" class="w-full max-w-xs md:max-w-md rounded-xl">
            </div>
            <div class="w-full text-center">
                <h3 class="text-center text-2xl text-red-600">{{ $t('portfolio.projects.pokemon.title') }}</h3>
                <p class="text-justify p-3">{{ $t('portfolio.projects.pokemon.description') }}</p>
                <div class="p-3 flex flex-wrap gap-3 justify-center">
                  <img src="@/assets/Logos/HTML5.svg" alt="Logo HTML 5" class="w-10">
                  <img src="@/assets/Logos/Sass.svg" alt="Logo Sass" class="w-10">
                  <img src="@/assets/Logos/JavaScript.svg" alt="Logo Javascript" class="w-10">
                  <img src="@/assets/Logos/Vue.js.svg" alt="Logo Vue" class="w-10">
                </div>
                <a href="https://pokemon-pokedex-vue.netlify.app/" target="_blank" class="md:text-xl bg-blue-500 hover:bg-blue-600 text-white inline-flex justify-center items-center px-5 py-3 rounded-lg gap-1 md:gap-2 mt-2">
                    Demo
                    <unicon name="navigator" fill="#FFFFFF" class="w-6 h-6"></unicon>
                </a>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide class="h-full w-full flex justify-center">
          <div class="w-full flex flex-col justify-center gap-5 lg:gap-14">
            <div class="w-full h-1/2 flex justify-center items-center">
              <img src="@/assets/Carrousell/Marvel-Super-Heroes.png" alt="Judotecnia Web Site" class="w-full max-w-xs md:max-w-md rounded-xl">
            </div>
            <div class="w-full text-center">
                <h3 class="text-center text-2xl text-red-600">{{ $t('portfolio.projects.marvel.title') }}</h3>
                <p class="text-justify p-3">{{ $t('portfolio.projects.marvel.description') }}</p>
                <div class="p-3 flex flex-wrap gap-3 justify-center">
                  <img src="@/assets/Logos/HTML5.svg" alt="Logo HTML 5" class="w-10">
                  <img src="@/assets/Logos/Sass.svg" alt="Logo Sass" class="w-10">
                  <img src="@/assets/Logos/JavaScript.svg" alt="Logo Javascript" class="w-10">
                  <img src="@/assets/Logos/Vue.js.svg" alt="Logo Vue" class="w-10">
                </div>
                <a href="https://marvel-api-search.netlify.app/" target="_blank" class="md:text-xl bg-blue-500 hover:bg-blue-600 text-white inline-flex justify-center items-center px-5 py-3 rounded-lg gap-1 md:gap-2 mt-2">
                    Demo
                    <unicon name="navigator" fill="#FFFFFF" class="w-6 h-6"></unicon>
                </a>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide class="h-full w-full flex justify-center">
          <div class="w-full flex flex-col justify-center gap-5 lg:gap-14">
            <div class="w-full h-1/2 flex justify-center items-center">
              <img src="@/assets/Carrousell/Cinema-DB.png" alt="Judotecnia Web Site" class="w-full max-w-xs md:max-w-md rounded-xl">
            </div>
            <div class="w-full text-center">
                <h3 class="text-center text-2xl text-red-600">{{ $t('portfolio.projects.cinemaDB.title') }}</h3>
                <p class="text-justify p-3">{{ $t('portfolio.projects.cinemaDB.description') }}</p>
                <div class="p-3 flex flex-wrap gap-3 justify-center">
                  <img src="@/assets/Logos/HTML5.svg" alt="Logo HTML 5" class="w-10">
                  <img src="@/assets/Logos/CSS3.svg" alt="Logo CSS3" class="w-10">
                  <img src="@/assets/Logos/JavaScript.svg" alt="Logo Javascript" class="w-10">
                </div>
                <a href="https://leonardorosas92.github.io/movies-api-rest-js/#home/" target="_blank" class="md:text-xl bg-blue-500 hover:bg-blue-600 text-white inline-flex justify-center items-center px-5 py-3 rounded-lg gap-1 md:gap-2 mt-8">
                    Demo
                    <unicon name="navigator" fill="#FFFFFF" class="w-6 h-6"></unicon>
                </a>
            </div>
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </section>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation } from 'swiper/modules';
export default {
  components: { Swiper, SwiperSlide },
  setup() {
    return {
      modules: [ Navigation ],
    };
  },
}
</script>
<style scoped lang="scss">

.swiper {
    width: 100%;
    height: 100%;
}
</style>