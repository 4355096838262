<template>
    <section id="about" class="flex flex-col gap-5 items-center min-h-screen px-1 pt-20">
        <h2 class="text-2xl md:text-3xl">{{ $t('about.title') }}</h2>
        <span class="text-sm md:text-lg md:mb-10 md:mt-5">{{ $t('about.subtitle') }}</span>
        <div class="p-2 flex flex-col md:flex-row-reverse items-center gap-6 md:gap-10">
            <div class="flex flex-wrap justify-center gap-3">
                <img src="@/assets/Perfil2.jpeg" alt="Image about me" class="w-56 rounded-lg">
                <img src="@/assets/Perfil3.jpeg" alt="Image about me" class="w-56 rounded-lg">
                <img src="@/assets/Perfil4.jpeg" alt="Image about me" class="w-56 rounded-lg">
                <img src="@/assets/Perfil5.jpeg" alt="Image about me" class="w-56 rounded-lg">
            </div>
            <div class="flex flex-col gap-10 md:w-1/2 ">
                <p class="md:text-lg text-gray-500 text-center mt-3">
                    {{ $t('about.description') }}
                </p>
                <div class="text-center">
                    <a v-if="$i18n.locale === 'en'" download="CV Leonardo Rosas" href="/files/EN_Leonardo_Rosas_CV.pdf" class="bg-blue-600 text-white inline-flex justify-center items-center px-5 py-3 gap-3 rounded-lg transition-all">
                        {{ $t('about.download') }}
                        <unicon name="download-alt" fill="#FFFFFF" class="w-6 h-6 animate-bounce "></unicon>
                    </a>
                    <a v-else download="CV Leonardo Rosas" href="/files/ES_Leonardo_Rosas_CV.pdf" class="bg-blue-600 text-white inline-flex justify-center items-center px-5 py-3 gap-3 rounded-lg transition-all">
                        {{ $t('about.download') }}
                        <unicon name="download-alt" fill="#FFFFFF" class="w-6 h-6 animate-bounce "></unicon>
                    </a>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {

}
</script>
